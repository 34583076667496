import React, {useState} from "react";
import {Grid, Typography, Box, TextField} from "@mui/material";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";

import styles from "./FormSelectize.module.css";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon  from '@mui/icons-material/CheckBox';
import Checkbox from "@mui/material/Checkbox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormSelectize = (props) => {

  return (
    <Grid item md={12} xs={12} container display={"flex"}>
      {props?.label ? (
        <Grid item md={12} xs={12} container>
          <Typography className={styles.labelText}>{props.label}</Typography>
          {props?.required ?
            <Box>
              <Typography className={styles.requiredText} mt={-0.3} pl={0.3}>*</Typography>
            </Box>
            : null}
        </Grid>
      ) : null}
      <Grid
        sx={[
          style.textInputView,
          props?.heightViewStyle ? props.heightViewStyle : style.heightView,
          props?.disabled
            ? style.disabledTextInputBgView
            : style.textInputBgView
        ]}
        className={[props.error ? styles.errorBorderStyle : styles.borderStyle]}
        item
        md={12}
        xs={12}
        container
      >
        <Autocomplete
          multiple={
            typeof props?.multiple !== "undefined" ? props?.multiple : true
          }
          size="small"
          disableClearable={props?.disableClearable ? props.disableClearable : false}
          options={props.options}
          disableCloseOnSelect
          disabled={props?.disabled ? props?.disabled : false}
          loading={props?.loading ? props.loading : false}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          sx={{
            "&.MuiAutocomplete-root": {
              width: "100%",
            },
            "& .MuiInputBase-input, .MuiAutocomplete-popper": {
              fontFamily: "IBMPlexSansRegular",
              fontSize: 14,
            },
          }}
          ListboxProps={{
            sx: {
              fontSize: 14,
              fontFamily: "IBMPlexSansRegular",
            },
          }}
          ChipProps={{
            sx: {
              "& .MuiChip-label": {
                fontSize: 12,
              },
            },
          }}
          value={props.value}
          filterOptions={(options, params) => {
            const filter = createFilterOptions()
            const filtered = filter(options, params)
            return [{ label: 'All', value: 'all' }, ...filtered]
          }}
          onChange={(event, newValue) => {
            if (newValue.find(option => option?.value == 'all'))
              return props.onChange(props?.value?.length === props?.options?.length ? [] : props.options)

            props.onChange(newValue)
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(prop, option, { selected }) => (
            <li {...prop}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={option?.value == 'all' ? !!(props?.value?.length === props?.options?.length) : selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={props?.inputRef}
              placeholder={props?.placeholder}
              variant={props?.variant ? props?.variant : "standard"}
              disabled={props?.disabled ? props?.disabled : false}
              sx={{
                "& .MuiInput-root": {
                  "&:before, :after, :hover:not(.Mui-disabled):before": {
                    borderBottom: 0,
                  },
                  transition: "0.2s",
                  paddingLeft: 1.5,
                },
                "& .Mui-focused": {
                  border: "1px solid #5a8dee",
                  minHeight: props?.height ? props.height : 36,
                  borderRadius: 1,
                  transition: "0.2s",
                  paddingLeft: 2,
                },
                input: {
                  "&::placeholder": {
                    color: "#CCC",
                    fontSize: 14,
                    fontFamily: "IBMPlexSansRegular",
                    opacity: 1,
                  },
                },
              }}
            />
          )}
        />
      </Grid>
      {props.error ? (
        <Box mt={0.7} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

export default FormSelectize;

const style = {
  textInputView: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFF",
    border: "1px solid #CCC",
    borderRadius: 1,
  },
  heightView: {
    minHeight: 36,
  },
  textInputBgView: {
    backgroundColor: "#FFF",
  },
  disabledTextInputBgView: {
    backgroundColor: "#e9ecee",
  },
  textInputValue: {
    fontFamily: "IBMPlexSansRegular",
    color: "#000",
    fontSize: 14,
    px: 1.5,
  },
};

