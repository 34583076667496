import React, { useEffect, useState, useRef } from "react";
import {Grid, Typography, Box, Modal, CircularProgress} from "@mui/material";
import dayjs from "dayjs";

import FormDatePicker from "./FormDatePicker";
import FormButton from "../components/FormButton";

import styles from "./BookAgainModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const BookAgainModal = (props) => {
  const [bookedDate, setBookedDate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (props.modalVisible) {
      setVisible(true);
    } else {
      setBookedDate(null);
      setError(null);
    }
  }, [props.modalVisible]);

  const onSubmitClick = () => {
    let errorText = {};
    if (bookedDate == null) {
      errorText = { bookedDate: "Booked Date is required" };
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }


    let updateData = {
      booked_date: (bookedDate && (bookedDate != null)) ? dayjs(bookedDate).format("YYYY-MM-DD") : null,
      status: 'active',
    }

    let data = {
      userUid: props?.data?.uid,
      data: updateData,
      checkStatus: 'booked'
    };

    props.onSubmit(JSON.stringify(data));
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  return (
    <Modal
      onClose={onCancelClick}
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{ md: 8, xs: 4 }}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle} />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            Book Again
          </Typography>
        </Grid>

        <>
          <Grid item md={12} xs={12} mt={3}>
            <FormDatePicker
              page={"BookAgainModal"}
              value={bookedDate}
              inputFormat="DD-MM-YYYY"
              onChange={setBookedDate}
              label={"BOOKED DATE"}
              error={error?.bookedDate}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            mt={3}
            display={"flex"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item md={2} xs={2}>
              <FormButton
                loading={props.btnLoading}
                btnStyleView={style.btnStyleView}
                title={"Submit"}
                onSubmit={onSubmitClick}
              />
            </Grid>
            <Grid ml={{ md: 2, xs: 4 }} item md={2} xs={2}>
              <FormButton
                btnStyleView={style.btnStyleView}
                btnType={"btn2"}
                title={"Cancel"}
                onSubmit={onCancelClick}
              />
            </Grid>
          </Grid>
        </>
      </Grid>
    </Modal>
  );
};

export default BookAgainModal;

const style = {
  btnStyleView: {
    height: 40,
    minWidth: 100,
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
