import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom'
import dayjs from "dayjs";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import FormDatePicker from "../../components/FormDatePicker";
import FormSelectize from "../../components/FormSelectize";

import {connect} from "react-redux";
import {addUser, updateApiStatus, getUserDetail, updateUser, deleteUser} from "../../redux/actions/userDataActions";

import styles from "./AddUser.module.css";

import {LOCATION_ARRAY} from '../../constants'

const AddUser = (props) => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const [userUid, setUserUid] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [scheduleId, setScheduleId] = useState("");
  const [bookedDate, setBookedDate] = useState(null);
  const [timePeriod, setTimePeriod] = useState("");
  const [location, setLocation] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let user_uid = urlParams?.user_id;
    if (user_uid) {
      let data = {
        userUid: user_uid,
      };
      setUserUid(user_uid)
      props.getUserDetail(JSON.stringify(data));
    } else {
      setUserUid('');
      setEmail('');
      setPassword('');
      setScheduleId('');
      setBookedDate(null);
      setTimePeriod('');
      setLocation([]);
      setError(null)
    }
  }, [])

  useEffect(() => {
    if (props?.userDetail != null) {
      let data = props?.userDetail;
      setUserUid(data?.uid);
      setEmail(data?.email);
      setPassword(data?.password);
      setScheduleId(data?.schedule_id);
      if ((data?.booked_date) && (data?.booked_date != null)) {
        setBookedDate(dayjs(data.booked_date));
      } else {
        setBookedDate(null);
      }
      setTimePeriod(data?.time_period);
      setLocation(data?.location);
    } else {
      setUserUid('');
      setEmail('');
      setPassword('');
      setScheduleId('');
      setBookedDate(null);
      setTimePeriod('');
      setLocation([]);
    }
    setError(null)
  }, [props?.userDetail])

  useEffect(() => {
    if (props?.apiStatus == 'ADD_USER_SUCCESS') {
      setEmail('')
      setPassword('')
      setScheduleId('')
      setLocation([]);
      setError(null)
      props?.updateApiStatus('')
    } else if (props?.apiStatus == 'DELETE_USER_SUCCESS') {
      navigate('/users')
      props?.updateApiStatus('')
    }
  }, [props?.apiStatus])

  const onAddUpdateClick = () => {
    let errorText = {};
    if (email == '') {
      errorText = {email: 'Please enter email'};
    } else {
      if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(email)) {
        errorText = {...errorText, email: "Please enter valid email address"};
      }
    }
    if (password == '') {
      errorText = {...errorText, password: 'Please enter password'};
    }
    if (timePeriod != '') {
      if (timePeriod == 0) {
        errorText = {...errorText, timePeriod: 'Please enter valid time period'};
      }
    }
    if (location.length === 0) {
      errorText = {...errorText, location: 'Please choose location'};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      userUid: userUid,
      email: email,
      password: password,
      scheduleId: scheduleId,
      timePeriod: timePeriod,
      bookedDate: (bookedDate && (bookedDate != null)) ? dayjs(bookedDate).format("YYYY-MM-DD") : "",
      location: location
    };

    if (userUid) {
      props.updateUser(JSON.stringify(data))
    } else {
      props.addUser(JSON.stringify(data))
    }
  };

  const onDeleteClick = () => {
    props.deleteUser(userUid)
  };

  if (props.userDetailLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <Container page={"users"} title={userUid ? "Edit User" : "Add User"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        className={styles.cardView}
        p={4}
      >

        <Grid item md={12} xs={12} mb={4}
              display={"flex"}
              alignItems={"flex-start"}>
          <Typography className={styles.titleText}>
            {userUid ? "Edit User" : "Add User"}
          </Typography>
        </Grid>

        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddUser"}
            required={true}
            error={error?.email}
            value={email}
            setValue={(val) => setEmail(val)}
            label={"EMAIL"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddUser"}
            required={true}
            error={error?.password}
            value={password}
            setValue={(val) => setPassword(val)}
            label={"PASSWORD"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddUser"}
            required={false}
            error={error?.scheduleId}
            value={scheduleId}
            setValue={(val) => setScheduleId(val)}
            label={"SCHEDULE ID"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormDatePicker
            page={"AddUser"}
            label={"BOOKED DATE"}
            value={bookedDate}
            required={false}
            inputFormat="DD-MM-YYYY"
            minDate={dayjs()}
            onChange={(val) => setBookedDate(val)}
            error={error?.bookedDate}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddUser"}
            required={false}
            error={error?.timePeriod}
            value={timePeriod}
            setValue={(val) => setTimePeriod(val?.replace(/[^0-9]/g, ""))}
            label={"TIME PERIOD (IN MONTHS)"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormSelectize
            page={"AddUser"}
            value={location}
            label={"LOCATION"}
            multiple={true}
            onChange={setLocation}
            options={LOCATION_ARRAY}
            error={error?.location}
            required={true}
          />
        </Grid>

        <Grid item md={12} xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              mt={3}>
          {userUid ?
            <Box mr={1}>
              <FormButton
                btnType={'delete'}
                btnDisabled={props?.userBtnLoading}
                loading={props?.deleteBtnLoading}
                title={"Delete"}
                onSubmit={onDeleteClick}
              />
            </Box>
            : null}

          <FormButton
            btnDisabled={props?.deleteBtnLoading}
            loading={props?.userBtnLoading}
            title={userUid ? "Update" : "Add"}
            onSubmit={onAddUpdateClick}
          />
        </Grid>

      </Grid>

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    userBtnLoading: state.userData.userBtnLoading,
    deleteBtnLoading: state.userData.deleteBtnLoading,
    apiStatus: state.userData.apiStatus,
    userDetail: state.userData.userDetail,
    userDetailLoading: state.userData.userDetailLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetail: (data) => dispatch(getUserDetail(data)),
    addUser: (data) => dispatch(addUser(data)),
    updateUser: (data) => dispatch(updateUser(data)),
    deleteUser: (id) => dispatch(deleteUser(id)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
