export const ENV = 'PROD';
export const AUTHENTICATION_FAILED = 'Authentication Failed';

export const THEME_COLOR = '#5a8dee';
export const THEME_COLOR2 = '#f3f4f4';
export const THEME_COLOR3 = '#677788';

export const LOCATION_ARRAY = [
  {
    label: 'Calgary',
    value: 89
  },
  {
    label: 'Ottawa',
    value: 92
  },
  {
    label: 'Toronto',
    value: 94
  },
  {
    label: 'Vancouver',
    value: 95
  }
];
export const LOCALE = 'en-ca';

export const UTC_OFFSET = "+05:30";

export const NAVBAR_HEIGHT = 64;
export const FOOTER_HEIGHT = 40;


