import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {listCronDuration, addUpdateCronDuration, updateApiStatus} from "../../redux/actions/userDataActions";

import {LOCATION_ARRAY, LOCALE} from '../../constants'

import styles from "./Settings.module.css";

const Settings = (props) => {
  const [cronDuration, setCronDuration] = useState("");
  const [editBtn, setEditBtn] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(props?.apiStatus == 'ADD_UPDATE_CRON_DURATION_SUCCESS') {
      setError(null)
      props?.updateApiStatus('')
    }
  },[props?.apiStatus])

  useEffect(() => {
    props?.listCronDuration()
  },[])

  useEffect(() => {
    if(props?.cronDuration) {
      setCronDuration(props?.cronDuration)
      setEditBtn(true)
    }
  },[props?.cronDuration])

  const onSaveClick = () => {
    let errorText = {};
    if (cronDuration == '') {
      errorText = {cronDuration: 'Please enter cron duration'};
    } else if (!/^[0-9]{1,100}$/.test(cronDuration)) {
      errorText = {cronDuration: 'Please enter valid cron duration'};
    }

      if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      cronDuration: cronDuration,
    };

    props.addUpdateCronDuration(JSON.stringify(data))
  };

  const onEditClick = () => {
    setEditBtn(false)
  }

  return (
    <Container page={"settings"} title={"Settings"}>
      <Grid
        item
        md={5.8}
        xs={12}
        container
        display={"flex"}
        flexDirection={'column'}
        className={styles.cardView}
        p={4}
      >
        <Box>
          <Typography className={styles.labelText}>Facility ID</Typography>
          <Typography className={styles.valueText}>{LOCATION_ARRAY.map((item) => item?.value).join(',')}</Typography>
        </Box>
        <Box mt={2}>
          <Typography className={styles.labelText}>Locale</Typography>
          <Typography className={styles.valueText}>{LOCALE}</Typography>
        </Box>
      </Grid>

      <Grid
        item
        md={5.8}
        xs={12}
        container
        display={"flex"}
        flexDirection={'column'}
        className={styles.cardView}
        p={4}
        mt={4}
      >
        <Grid item md={12} xs={12} mt={2}>
          <FormTextInput
            page={"CronDuration"}
            required={true}
            disabled={editBtn}
            error={error?.cronDuration}
            value={cronDuration}
            setValue={(val) => setCronDuration(val)}
            label={"CRON DURATION (IN MINS)"}
          />
        </Grid>

        <Grid item md={12} xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              mt={3}>
          <FormButton
            loading={props?.cronDurationBtnLoading}
            btnStyleView={style.btnStyleView}
            title={editBtn ? "Edit" : "Save"}
            onSubmit={editBtn ? onEditClick : onSaveClick}
          />
        </Grid>

      </Grid>

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    cronDurationBtnLoading: state.userData.cronDurationBtnLoading,
    cronDuration: state.userData.cronDuration,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateCronDuration: (data) => dispatch(addUpdateCronDuration(data)),
    listCronDuration: (data) => dispatch(listCronDuration(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

const style = {}
